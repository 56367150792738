import clsx from 'clsx';
import React from 'react';

function BannerLoadingSkeleton({ className }: { className?: string }) {
  return (
    <div
      className={clsx(
        'animate-pulse rounded-lg bg-gradient-to-b from-platin p-2 shadow-sm',
        className
      )}
    ></div>
  );
}

export default BannerLoadingSkeleton;
