import clsx from 'clsx';

export function CheckIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      aria-label={'check-icon'}
      className={clsx(props.className)}
      width='11.756px'
      height='8px'
      viewBox='0 0 12 8'
      fill='none'
      {...props}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.5045 0.285717C11.1567 -0.0810199 10.5768 -0.0971719 10.2095 0.251515L4.36152 5.79755L1.5722 2.93369C1.21892 2.57123 0.639692 2.56328 0.276926 2.91661C-0.0855303 3.26957 -0.0931652 3.84942 0.259803 4.21188L3.67968 7.72307C3.8589 7.90719 4.09708 8 4.33588 8C4.56246 8 4.78903 7.91633 4.96644 7.74871L11.4703 1.58073C11.8373 1.23262 11.8529 0.652764 11.5045 0.285717Z'
        fill='white'
      />
    </svg>
  );
}
