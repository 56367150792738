// [TODO] delete this file after refactor is done and remove the slice
import { createSlice } from '@reduxjs/toolkit';
type TodoState = {
  modalOpen: boolean;
};
const initialState: TodoState = {
  modalOpen: false,
};
export const PresceriptionModal = createSlice({
  name: 'alternativeModal',
  initialState,
  reducers: {
    setPresceriptionModal: (state, action) => {
      state.modalOpen = action.payload;
    },
  },
});
export const { setPresceriptionModal } = PresceriptionModal.actions;
export default PresceriptionModal.reducer;
