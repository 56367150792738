// [TODO] delete this file after refactor is done and remove the slice
import { createSlice } from '@reduxjs/toolkit';
type TodoState = {
  modalOpen: boolean;
  alternativeProducts: [];
  storeZoneId?: string;
};
const initialState: TodoState = {
  modalOpen: false,
  alternativeProducts: [],
  storeZoneId: '',
};
export const AlternativeModal = createSlice({
  name: 'alternativeModal',
  initialState,
  reducers: {
    setAlternativeModal: (state, action) => {
      state.modalOpen = action.payload;
    },
    setAlternativeProducts: (state, action) => {
      state.alternativeProducts = action.payload;
    },
    setStoreZoneId: (state, action) => {
      state.storeZoneId = action.payload;
    },
  },
});
export const { setAlternativeModal, setAlternativeProducts, setStoreZoneId } =
  AlternativeModal.actions;
export default AlternativeModal.reducer;
