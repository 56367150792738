import { toast } from 'react-toastify';
import Notification from 'components/notifications';

export const showToast = (text: string, theme: 'success' | 'error') => {
  toast(
    <Notification
      componentProps={{
        closeIcon: true,
        text,
      }}
    />,
    {
      autoClose: 800,
      hideProgressBar: true,
      theme,
      position: 'top-center',
    }
  );
};
