export const homeHref = '/';
export const loginHref = '/login';
export const myCartHref = '/my-cart';
export const reportAnIssueHref = '/report-an-issue';
export const myWishlistHref = '/account/my-wishlist';
export const registerHref = '/register';
export const checkoutHref = '/checkout';
export const payfortReturnHref = '/checkout/payfort-return';
export const confirmationHref = '/checkout/confirmation';
export const accountHref = '/account';
export const myAccountHref = '/account/my-account';
export const addressBookHref = '/account/address-book';
export const paymentCardsHref = '/account/payment-cards';
export const myOrdersHref = '/account/my-orders';
export const myOfflineOrdersHref = '/account/my-offline-orders';
export const orderDetailsHref = '/account/order-details';
export const reOrderHref = '/account/re-order';
export const wishlistHref = '/account/my-wishlist';
export const referEarnHref = '/account/refer-earn';
export const consenstManagementHref = '/account/consent-management';
export const redirectToMyCartLoginHref = `${loginHref}?redirect=${myCartHref}`;
export const logoutHref = '/logout';
export const plpPageHref = '/search';
export const erxHref = '/erx';
export const erxInsuranceOrderHref = `${erxHref}/insurance-order`;
export const erxCashOrderHref = `${erxHref}/cash-order`;
export const refillOrderHref = `${erxHref}/refill-order`;
