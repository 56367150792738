import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { COOKIE_IMPERSONATE } from 'utils/constant-cookies';
import { getCookieInClient } from 'utils/cookie';

export interface State {
  redirectAfterAddressUpdate?: string;
  isSelectAddressModalOpen: boolean;
  isSelectShippingMethodOpen: boolean;
  isMapModalOpen: boolean;
  isMapOptionsOpen: boolean;
  isDeleting: boolean;
  isUpdatingOrSaving: boolean;
  zoneId: string;
  editAddressCmsContent: EditAddressCmsType;
}

const initialCmsContent: EditAddressCmsType = {
  address: '',
  address_type: {
    address_type_field: {
      label: {
        ar: '',
        en: '',
      },
    },
    address_type_items: '',
    label: {
      ar: '',
      en: '',
    },
  },
  header: {
    ar: '',
    en: '',
  },
  icon: '',
  map_data: {
    search_address_fields: {
      icon: '',
      placeholder: {
        ar: '',
        en: '',
      },
    },
  },
};
const initialState: State = {
  redirectAfterAddressUpdate: '',
  isSelectAddressModalOpen: false,
  isSelectShippingMethodOpen: false,
  isMapModalOpen: false,
  isMapOptionsOpen: true,
  isDeleting: false,
  isUpdatingOrSaving: false,
  zoneId: '',
  editAddressCmsContent: initialCmsContent,
};
export type EditAddressCmsType = {
  address: string;
  address_type: {
    address_type_field: {
      label: {
        ar: string;
        en: string;
      };
    };
    address_type_items: string;
    label: {
      ar: string;
      en: string;
    };
  };
  header: {
    ar: string;
    en: string;
  };
  icon: string;
  map_data: {
    search_address_fields: {
      icon: string;
      placeholder: {
        ar: string;
        en: string;
      };
    };
  };
};

export const selectAddressModalWithPermission = createAsyncThunk(
  'address/selectAddressModalWithPermission',
  async (params: {
    value: boolean;
    language: LanguageCodesType;
    region: RegionCodesType;
  }) => {
    const { value, language, region } = params;
    const impR = getCookieInClient(COOKIE_IMPERSONATE);
    if (value === true && impR) {
      const response = await fetch(
        `/api/impersonate/permission?requiredRight=access_guest_address_book&language=${language}&region=${region}`
      );
      const data = await response.json();
      if (!data?.has_permission) {
        window.location.href = '/?impUnsatisfied=true';
        return false;
      } else {
        return true;
      }
    } else {
      return value;
    }
  }
);

export const selectShippingMethodModalWithPermission = createAsyncThunk(
  'address/selectShippingMethodModalWithPermission',
  async (params: {
    value: boolean;
    language: LanguageCodesType;
    region: RegionCodesType;
  }) => {
    const { value, language, region } = params;
    const impR = getCookieInClient(COOKIE_IMPERSONATE);
    if (value === true && impR) {
      const response = await fetch(
        `/api/impersonate/permission?requiredRight=access_guest_address_book&language=${language}&region=${region}`
      );
      const data = await response.json();
      if (!data?.has_permission) {
        window.location.href = '/?impUnsatisfied=true';
        return false;
      } else {
        return true;
      }
    } else {
      return value;
    }
  }
);

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setRedirectAfterAddressUpdate: (
      state: State,
      action: PayloadAction<string>
    ) => {
      state.redirectAfterAddressUpdate = action.payload;
    },
    setMapModal: (state: State, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.isMapOptionsOpen = true;
      }
      state.isMapModalOpen = action.payload;
      if (!action.payload) {
        state.isMapOptionsOpen = false;
        state.isUpdatingOrSaving = false;
      }
    },
    setMapOptions: (state: State, action: PayloadAction<boolean>) => {
      state.isMapOptionsOpen = action.payload;
    },
    setIsDeleting: (state: State, action: PayloadAction<boolean>) => {
      state.isDeleting = action.payload;
    },
    setIsUpdatingOrSaving: (state: State, action: PayloadAction<boolean>) => {
      state.isUpdatingOrSaving = action.payload;
    },
    setzoneId: (state: State, action: PayloadAction<string>) => {
      state.zoneId = action.payload;
    },
    setEditAddressCmsContent: (
      state: State,
      action: PayloadAction<EditAddressCmsType>
    ) => {
      state.editAddressCmsContent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      selectAddressModalWithPermission.fulfilled,
      (state, action) => {
        state.isSelectAddressModalOpen = action.payload;
      }
    );
    builder.addCase(
      selectShippingMethodModalWithPermission.fulfilled,
      (state, action) => {
        state.isSelectShippingMethodOpen = action.payload;
      }
    );
  },
});

export const {
  setRedirectAfterAddressUpdate,
  setMapModal,
  setIsDeleting,
  setIsUpdatingOrSaving,
  setMapOptions,
  setzoneId,
  setEditAddressCmsContent,
} = addressSlice.actions;
export default addressSlice.reducer;
