'use client';

import clsx from 'clsx';
import { useMemo } from 'react';
import { Underline } from 'components/buttons/underline';
import { WarningIcon, SmallCloseIcon, CheckCircleIcon } from 'components/icon';
import { NotificationProps } from 'types/prop-types';

export default function Notifications({
  closeToast,
  toastProps,
  componentProps,
  icon,
}: NotificationProps) {
  const theme = toastProps?.theme || 'warning-primary';
  const url = componentProps?.url || null;
  const closeIcon = componentProps?.closeIcon || false;
  const text = componentProps?.text || '';
  const themeIsWarning = theme.includes('warning');

  const setColor = useMemo(() => {
    switch (theme) {
      case 'warning-primary':
        return 'bg-pastel-yellow text-gray-dark';
      case 'warning-secondary':
        return 'bg-light-rose text-error';
      case 'warning-tertiary':
        return 'bg-pastel-yellow text-orange';
      case 'success':
        return 'bg-green text-white';
      case 'error':
        return 'bg-red text-white';
      case 'add-to-cart':
        return 'bg-blue text-white';
    }
  }, [theme]);

  const setIconColor = useMemo(() => {
    switch (theme) {
      case 'warning-primary':
        return 'text-orange';
      case 'warning-secondary':
        return 'text-error';
      case 'warning-tertiary':
        return 'text-orange';
      case 'success':
        return 'text-white';
      case 'error':
        return 'text-white';
    }
  }, [theme]);

  const setIcon = useMemo(() => {
    if (icon) return icon;
    if (theme !== 'add-to-cart') {
      switch (theme) {
        case 'success':
          return (
            <CheckCircleIcon
              className={`has-color absolute fill-current sm:relative ${setIconColor}`}
            />
          );
        default:
          return (
            <WarningIcon
              className={`has-color absolute fill-current sm:relative ${setIconColor}`}
            />
          );
      }
    }
  }, [theme, setIconColor, icon]);

  return (
    <div
      className={clsx(
        `${setColor} relative flex w-full items-center justify-between rounded-pop-up py-[1rem]`,
        'ltr:px-3 sm:ltr:pl-3 sm:ltr:pr-4 rtl:flex-row-reverse rtl:px-3 sm:rtl:pl-4 sm:rtl:pr-3'
      )}
    >
      <div
        className={`${
          !themeIsWarning ? 'sm:justify-center' : ''
        } toast-wrapper flex items-center space-x-5 sm:space-x-3 rtl:flex-row-reverse rtl:space-x-reverse`}
      >
        {setIcon}
        <span
          className={clsx(
            'line-clamp-2 h-full sm:w-auto',
            'text-custom-sm font-semibold',
            'ltr:pl-2 ltr:pr-4 sm:ltr:px-0 rtl:pl-4 rtl:pr-2 sm:rtl:px-0',
            `${
              theme === 'add-to-cart'
                ? 'text-center'
                : 'ltr:text-left rtl:text-right'
            }`
          )}
        >
          {text}
        </span>
      </div>
      {url && themeIsWarning && <Underline href='/'>Link</Underline>}
      {closeIcon && (
        <button onClick={closeToast} className='ltr:pl-4 rtl:pr-4'>
          <SmallCloseIcon
            className={`has-color fill-current ${
              themeIsWarning ? 'text-gray-dark' : 'text-white'
            }`}
          />
        </button>
      )}

      {theme === 'add-to-cart' && (
        <div className='absolute -bottom-[5px] inline-block w-6 overflow-hidden ltr:right-16 rtl:left-16'>
          <div className='h-2 w-2 origin-bottom-right rotate-45 transform bg-blue'></div>
        </div>
      )}
    </div>
  );
}
