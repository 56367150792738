import clsx from 'clsx';

export function ClockAlarmIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      aria-label={'clock-alarm'}
      className={clsx(props.className)}
      width='17'
      height='21'
      viewBox='0 0 17 21'
      fill='none'
      {...props}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.0397 6.70621L15.8283 5.92206C16.1347 5.61734 16.1347 5.12326 15.8283 4.8187C15.5219 4.51398 15.0252 4.51398 14.7187 4.8187L13.9302 5.60284C12.6072 4.50665 10.9995 3.84442 9.28464 3.68939V2.11911H10.0431C10.4764 2.11911 10.8277 1.76968 10.8277 1.33878C10.8277 0.907867 10.4764 0.558594 10.0431 0.558594H6.95688C6.52355 0.558594 6.17232 0.907867 6.17232 1.33878C6.17232 1.76968 6.52355 2.11911 6.95688 2.11911H7.71536V3.68939C3.42259 4.07758 0 7.6637 0 12.106C0 16.7776 3.8016 20.5586 8.50008 20.5586C13.1978 20.5586 17 16.7782 17 12.106C17 10.1104 16.3089 8.22064 15.0397 6.70621ZM8.49992 18.9981C4.67838 18.9981 1.56913 15.9063 1.56913 12.106C1.56913 8.30579 4.67838 5.21405 8.49992 5.21405C12.3216 5.21405 15.4307 8.30579 15.4307 12.106C15.4307 15.9063 12.3216 18.9981 8.49992 18.9981ZM12.1361 8.49026C12.4425 8.79498 12.4425 9.28906 12.1361 9.59363L9.05478 12.6577C8.74835 12.9625 8.2515 12.9625 7.94522 12.6577C7.63879 12.353 7.63879 11.8589 7.94522 11.5544L11.0264 8.49026C11.3328 8.18555 11.8297 8.18555 12.1361 8.49026Z'
        fill='#EA264D'
      />
    </svg>
  );
}
