'use client';

import React, { Children, ReactNode, useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/grid';
import './base-swiper-style.css';
import { Autoplay, Grid, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Breakpoints } from 'types';
import BannerLoadingSkeleton from 'components/banner-loading-skeleton';

export type CustomSwiperType = SwiperProps & {
  fadelast?: 'show' | 'hide';
  fadeLastBreakpoints?: Breakpoints[];
  isEnd?: boolean;
};

const defaultProps: CustomSwiperType = {
  navigation: true,
  pagination: false,
  spaceBetween: 5,
  modules: [Pagination, Navigation, Autoplay, Grid],
  fadelast: 'hide',
  lazyPreloadPrevNext: 4,
};

const BaseSwiper = ({
  children,
  swiperProps,
  className,
  loadingSkeleton = <BannerLoadingSkeleton />,
}: {
  children: ReactNode;
  swiperProps?: CustomSwiperType;
  className?: string;
  loadingSkeleton?: ReactNode;
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const props = { ...defaultProps, ...swiperProps };
  if (props.grid) {
    const breakpointKeys = Object.keys(props.breakpoints || {});
    breakpointKeys.forEach((key) => {
      const breakpoint = props.breakpoints?.[key] ?? {};
      if (breakpoint.grid) return;
      breakpoint.grid = props.grid;
    });
  }

  const fadeLast = props.fadelast === 'show';
  const fadeLastClasses = fadeLast ? 'lg:fade-last-slide' : '';

  const [localClassName, setLocalClassName] = useState(
    `${className} ${fadeLastClasses}` || ''
  );
  const childrenWithSlides = Children.map(children, (child, index) => (
    <SwiperSlide key={index}>{child}</SwiperSlide>
  ));
  return (
    <>
      {loading ? (
        loadingSkeleton
      ) : (
        <Swiper
          /* TODO last slide bounce back on special multilines */
          /*  centeredSlides={true}
          centeredSlidesBounds={true} */
          onReachEnd={() => {
            const childrenArray = React.Children.toArray(children);
            if (!props.loop && childrenArray.length > 0) {
              setLocalClassName(localClassName.replace(fadeLastClasses, ''));
            }
          }}
          onSlidePrevTransitionStart={() =>
            fadeLast &&
            setLocalClassName(localClassName + ' ' + fadeLastClasses)
          }
          onSlidesUpdated={() => {
            if (className?.includes('special-multiline')) {
              const sliderElem: NodeListOf<HTMLElement> =
                document.querySelectorAll('.special-multiline');
              sliderElem.forEach((slider) => {
                const slideHeight = slider.querySelector<HTMLElement>(
                  '.swiper-slide:nth-of-type(2) .flex-col'
                )!.offsetHeight;
                const slideWidth = slider.querySelector<HTMLElement>(
                  '.swiper-slide:nth-of-type(2) .flex-col'
                )!.offsetWidth;
                const slideMarginBottom = parseInt(
                  slider.querySelector<HTMLElement>(
                    '.swiper-slide:nth-of-type(2)'
                  )?.style.marginTop || '0'
                );
                slider.setAttribute(
                  'style',
                  `height: ${slideHeight * 2 + 80}px`
                );
                slider
                  .querySelector<HTMLElement>('.swiper-slide')!
                  .style.setProperty(
                    'height',
                    `${slideHeight * 2 + slideMarginBottom}px`,
                    'important'
                  );
                slider
                  .querySelector<HTMLElement>('.swiper-slide')!
                  .style.setProperty(
                    'width',
                    `${
                      className.includes('special-multiline-tall')
                        ? slideWidth * 2 + slideMarginBottom
                        : slideHeight * 2 + slideMarginBottom
                    }px`,
                    'important'
                  );
              });
            }
          }}
          className={localClassName}
          {...{ ...props, fadeLastBreakpoints: undefined }}
        >
          {childrenWithSlides}
        </Swiper>
      )}
    </>
  );
};

export default BaseSwiper;
