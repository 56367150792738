// [TODO] delete this file after refactor is done and remove the slice
import { createSlice } from '@reduxjs/toolkit';
type TodoState = {
  modalOpen: boolean;
  productInfo: {
    id: string;
    rates: {
      avgRating: number;
      totalRatings: number;
    };
  };
};
const initialState: TodoState = {
  modalOpen: false,
  productInfo: {
    id: '',
    rates: {
      avgRating: 0,
      totalRatings: 0,
    },
  },
};
export const BundleModal = createSlice({
  name: 'Bundle Products',
  initialState,
  reducers: {
    setBundleModal: (state, action) => {
      state.modalOpen = action.payload;
    },
    setBundleProductInfo: (state, action) => {
      state.productInfo = action.payload;
    },
  },
});
export const { setBundleModal, setBundleProductInfo } = BundleModal.actions;
export default BundleModal.reducer;
