// [TODO] delete this file after refactor is done and remove the slice
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ProductItemType } from 'types/cms/product-carousel';
import {
  AlgoliaProduct,
  RemoveProductType,
  UpdateProductType,
} from 'types/product';
import {
  getBasketProductAvailabilitiesThunk,
  getMiniBasketThunk,
  updateBasketThunk,
} from './basket.reducer';
import { BasketProductAvailabilitiesType } from 'types/cart';
import { addWishlist } from 'lib/nahdi-middleware/account/wishlist/add-customer-wishlist';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { RootState } from './types';

type WishlistDataType = {
  id: any;
  items: {
    id: number | string;
    product: {
      sku: string | number;
      id: string;
      data: ProductItemType | AlgoliaProduct;
    };
  }[];
  items_count: number;
  loading?: boolean;
};
type WishlistStateType = {
  data: WishlistDataType;
  id?: string;
};
const initialState: WishlistStateType = {
  data: {
    id: '',
    items: [],
    items_count: 0,
    loading: true,
  },
};

export type AddWishlistType = {
  sku: string;
  quantity: number;
};

export const addWishlistThunk = createAsyncThunk(
  'wishlist/addWishlistThunk',
  async (
    params: {
      products: AddWishlistType[];
      language: LanguageCodesType;
      region: RegionCodesType;
    },
    { getState, rejectWithValue }
  ) => {
    const { products, language, region } = params;
    const reduxRoot = getState() as RootState;
    const wishlistStore = reduxRoot.wishlistReducer.data;

    const response = await addWishlist({
      body: products,
      wishlistId: wishlistStore?.id || '',
      language,
      region,
    });

    if (!response.id) {
      rejectWithValue('error');
    }

    const existingItems = wishlistStore?.items || [];
    const filteredWishlistItems = products.filter((wishlistItem) => {
      return !existingItems.some((existingItem: any) => {
        const productData = existingItem.product.data as ProductItemType;
        return productData?.sku_id === wishlistItem.sku;
      });
    });
    const updatedWishlistInfo = {
      ...wishlistStore,
      items: [...existingItems, ...filteredWishlistItems],
    };
    return updatedWishlistInfo as WishlistDataType;
  }
);

export const moveGlobalItemsToWishlistThunk = createAsyncThunk(
  'wishlist/moveGlobalItemsToWishlist',
  async (
    params: { language: LanguageCodesType; region: RegionCodesType },
    { getState, rejectWithValue, dispatch }
  ) => {
    const { language, region } = params;
    const reduxRoot = getState() as RootState;

    const cart = reduxRoot.basket;
    const availabilityResponse = await dispatch(
      getBasketProductAvailabilitiesThunk({ language, region })
    );
    const cartAvailability =
      availabilityResponse.payload as BasketProductAvailabilitiesType;

    const wishlistProducts: RemoveProductType[] = [];
    const productsToRemoveFromBasket: UpdateProductType[] = [];
    cart.items.forEach((cartItem: any) => {
      if (
        cartAvailability.lookUpFormat[cartItem.product.sku]?.shipment_type ===
        'global'
      ) {
        wishlistProducts.push({ quantity: 1, sku: cartItem.product.sku });
        productsToRemoveFromBasket.push({ quantity: 0, id: cartItem.id });
      }
    });

    const wishlistAddResult = await dispatch(
      addWishlistThunk({ products: wishlistProducts, language, region })
    );
    if (wishlistAddResult.meta.requestStatus === 'rejected') {
      return rejectWithValue('Add Wishlist Error');
    }
    dispatch(
      updateBasketThunk({
        product: productsToRemoveFromBasket,
        language,
        region,
      })
    );
    dispatch(getMiniBasketThunk({ language, region }));
    return 'success';
  }
);
export const Wishlist = createSlice({
  name: 'Wishlist',
  initialState,
  extraReducers(builder) {
    builder.addCase(addWishlistThunk.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
  reducers: {
    setAddWishlist: (state, action) => {
      const updatedWishlistInfo = action.payload;
      state.data = updatedWishlistInfo;
    },
    setDeleteWishlist: (state, action) => {
      const updatedWishlistInfo = action.payload;
      state.data = updatedWishlistInfo;
    },
    setWishlist: (state, action) => {
      state.data = action.payload;
      state.data.loading = false;
    },
  },
});
export const { setAddWishlist, setDeleteWishlist, setWishlist } =
  Wishlist.actions;
export default Wishlist.reducer;
